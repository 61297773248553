<script setup lang="ts">
import { WithdrawalNotVerifiedCard, WithdrawalSection } from '~/src/features/withdrawal';
import { useIsUserFullVerified } from '~/src/features/user';

const isFullVerified = useIsUserFullVerified();
</script>

<template>
	<WithdrawalSection v-if="isFullVerified" />

	<WithdrawalNotVerifiedCard v-else />
</template>
